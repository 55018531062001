/* Add some variables containing theme colors and page layout */
:root{
  --form-background-color: #F5F7FA; /* original: ebebeb alternative: F5F7FA ffffff */ 

  --ebrains-color-light: #9CE142;
  --ebrains-color-medium: #00C959;
  --ebrains-color-dark: #00A595;

  /* Color variations are obtained by inserting the main color in 
  Ant Design's theme editor https://ant.design/theme-editor */

  /* Color on buttons on official ebrains page: */
  --button-color-primary: #45b07c; 
  --button-color-primary-hover: #68bd91;
  --button-color-primary-active-hover: #2f8a61;
  --button-color-primary-active: #2f8a61e0;

  /* Using the ebrains-color-dark: */
  /* --button-color-primary: #00a595;
  --button-color-primary-hover: #1db39f;
  --button-color-primary-active-hover: #008077;
  --button-color-primary-active: #008077e0; */

  --button-color-secondary: #00c959;
  --button-color-secondary-hover: #22d66d;
  --button-color-secondary-active-hover: #00a34f;
  --button-color-secondary-active: #00a34fe0;
  
  /* Light red (not used) */
  --button-color-error: #ff4d68;
  --button-color-error-hover: #ff7585;
  --button-color-error-active-hover: #d93654;
  --button-color-error-active: #d93654e0;

  --button-color-error: #686868;
  --button-color-error-hover: #b56864;
  --button-color-error-active-hover: #822c2e;
  --button-color-error-active: #822c2ee0;

  --input-focus-color: #686868;
  --input-tick-color: var(--button-color-primary);

  --max-content-width: 980px;  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {  
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-footer {
  color: #ffffff;
  background-color:#1F2933;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
}

.container-gradient {
  background-image: linear-gradient(to left,  var(--ebrains-color-light), var(--ebrains-color-medium), var(--ebrains-color-dark));
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 9996;
}

.container-subheader {
  background-color: var(--form-background-color);
  margin-bottom: 40px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 9998;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px, rgba(0, 0, 0, 0.05) 0px 1px 4px 0px, rgba(0, 0, 0, 0.05) 0px 2px 8px 0px;
}
.ant-dropdown {
  z-index: 9999;
}

.privacy-notice {
  width: 60%;
  display: inline-block;
  text-align: left;
  margin: 0 auto;
}

.subheader-menu {
  width: 30%;
  margin-right: 0px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  text-align: right;
  vertical-align: top;
}

.subheader-login {
  width: 10%;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  text-align: right;
  vertical-align: top;
}

.notice {
  background-color: var(--form-background-color);
}
.notice-content {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px 10px 0px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Specify style of main containers */

.content-container {
  margin-top: 0px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 60px 5px 60px;
  max-width: var(--max-content-width);
}


.container-content-subheader {
  margin-top: 0px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 20px 5px 20px;
  max-width: var(--max-content-width);
}

.container-header {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 60px 0px 60px;
  max-width: var(--max-content-width);
  border-radius: 20px;
  z-index: 9997;
}

.container-form {
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 60px 60px 60px;
  max-width: var(--max-content-width);
  background-color: var(--form-background-color);
  border-radius: 20px;
}

.container-footer {
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 5px;
  color: #ffffff;
}

/* Some specific component styling */

/* links */
a {
  color: var(--button-color-primary);
}

/* inputs */
/* input:focus { 
  border-color: var(--button-color-primary);
  outline-color: var(--button-color-primary);
  box-shadow: 0 0 3px var(--button-color-primary);
  outline-offset: 0px;
  outline: none;
} */

.form-control:focus { 
  border-color: var(--input-focus-color);
  outline-color: var(--input-focus-color);
  box-shadow: 0 0 3px var(--input-focus-color);
  outline-offset: 0px;
  outline: none;
}

input[type=checkbox] {
  accent-color: var(--input-tick-color);
}
input[type=radio] {
  accent-color: var(--input-tick-color);
}

.result .react-json-view {
  padding-bottom: 10px;
}

.ant-steps-item-process {
  border-color: var(--button-color-primary);
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot {
  background: var(--button-color-primary);
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  border-color: var(--button-color-primary);
}

/* 
.ant-steps-icon-dot {
  background: var(--button-color-primary);
}
.ant-steps-item-icon {
  background: var(--button-color-primary);
  border-color: var(--button-color-primary);
}
.ant-steps-item-tail {
  background-color: var(--button-color-primary);
}
.ant-steps-item-tail::after {
  background-color: var(--button-color-primary);
} 
*/



/* General style for all buttons */
.btn {
  padding: 6px 12px 6px 12px;
  border-width: 1px;
  font-size: medium;
  font-weight: 600;
}
.btn:hover {
  padding: 5px 11px 5px 11px;
  border-width: 2px;
  font-size: medium;
}

/* Styles for the next button */
.btn-primary {
  color: #ffffff;
  min-width: 100px;
  background-color: var(--button-color-primary);
  border-color: var(--button-color-primary);
}
.btn-primary:hover {
  background-color: var(--button-color-primary-hover);
  border-color: var(--button-color-primary-hover);
}
.btn-primary:active {
  background-color: var(--button-color-primary-active);
  border-color: var(--button-color-primary-active);
}
.btn-primary:hover:active {
  background-color: var(--button-color-primary-active-hover);
  border-color: var(--button-color-primary-active-hover);
}
.btn-primary:hover:disabled {
  background-color: var(--button-color-primary-active-hover);
  border-color: var(--button-color-primary-active-hover);
}

.custom-button .anticon {
  vertical-align: middle;
}

/* Variation where button is gray-bluish (keep just in case) */
/* .btn-default {
  margin-right: 10px;
  color: #1F2933;
  border-color: #1f29338a;
  background-color: var(--form-background-color);
} */

.btn-default {
  color: var(--button-color-primary);
  background-color: #ffffff;
  border-color: var(--button-color-primary);
  background-color: var(--form-background-color);
}
.btn-default:hover {
  color: var(--button-color-primary-hover);
  border-color: var(--button-color-primary-hover);
  background-color: var(--form-background-color);
}
.btn-default:active {
  color: var(--button-color-primary-active);
  border-color: var(--button-color-primary-active);
  background-color: var(--form-background-color);
}
.btn-default:hover:active {
  color: var(--button-color-primary-active-hover);
  border-color: var(--button-color-primary-active-hover);
  background-color: var(--form-background-color);
}

.link-button {
  padding: 0px 0px 0px 0px;
  border-width: 0px;
  font-size: small;
  font-weight: 600;
  color: var(--button-color-primary);
  background-color: transparent;
  padding-right: 15px;
  background: url(./components/icons/up_arrow.svg) no-repeat;
  background: url(./components/icons/down_arrow.svg) no-repeat;
  background-position: 100% 50%;
}

.link-button:hover {
  text-decoration: underline;
}

/* Buttons in the array list */

.array-item-add .btn-add {
  content:'Add Item'; 
  float: right;
  min-width: 50px;
  max-width: 50px;
  margin-right: calc(1% + 11px);  /* 15 + 10 + 1 to align with remove button */
  color: var(--button-color-primary);
  border-color: var(--button-color-primary);
  background-color: var(--form-background-color);
}
.array-item-add .btn-add:hover {
  color: var(--button-color-primary-hover);
  border-color: var(--button-color-primary-hover);
  background-color: var(--form-background-color);
}
.array-item-add .btn-add:active {
  color: var(--button-color-primary-active);
  border-color: var(--button-color-primary-active);
  background-color: var(--form-background-color);
}
.array-item-add .btn-add:hover:active {
  color: var(--button-color-primary-active-hover);
  border-color: var(--button-color-primary-active-hover);
  background-color: var(--form-background-color);
}

.array-item-toolbox {
  float: right;
  padding-right:1%;
}

.array-item-toolbox .btn-group {
  float: right;
}

.array-item-toolbox .btn-danger {
  margin: 0px 1px 0px 1px;
  min-width: 50px;
  color: var(--button-color-error);
  border-color: var(--button-color-error);
  background-color: var(--form-background-color);
}
.array-item-toolbox .btn-danger:hover {
  margin: 0px 0px 0px 0px;
  color: var(--button-color-error-hover);
  border-color: var(--button-color-error-hover);
  background-color: var(--form-background-color);
}
.array-item-toolbox .btn-danger:active {
  color: var(--button-color-error-active);
  border-color: var(--button-color-error-active);
  background-color: var(--form-background-color);
}
.array-item-toolbox .btn-danger:hover:active {
  color: var(--button-color-error-active-hover);
  border-color: var(--button-color-error-active-hover);
  background-color: var(--form-background-color);
}


.container-form .form-group.field .row.array-item-list .array-item {
  border: 1px solid #e5e5e5;
  display: flex;
  position: relative;
  padding: 20px 10px 0px 10px;
  border-radius: 6px;
  margin-left: 15px;
  margin-bottom: 20px;
  width: calc(100% - 30px);
}

/* Components in the form's footer i.e nav buttons etc */

.container-form .footer button {
  margin-top: 10px;
  min-width: 120px;
}
.container-form .footer button.btn-default {
  margin-right: 10px;
  background-color: #ffffff;
}

.container-form .footer .back-panel {
  padding-left: 0;
}

.container-form .footer .submit-panel {
  text-align: right;
  padding-right: 0;
}

/* Text in page footer */
.text-muted {
  color: #CCCED0;
}

.container-footer a {
  color: var(--button-color-primary);
}
.container-footer a:hover {
  color: #CCCED0;
}